import React from 'react';
import { CheckboxInput } from './styled';

interface CheckboxProps {
  id: number;
  type: string;
  handleClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  type,
  handleClick,
  isChecked
}) => (
  <CheckboxInput
    id={id.toString()}
    type={type}
    onChange={handleClick}
    checked={isChecked}
  />
);

export default Checkbox;
