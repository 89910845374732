import React from 'react';
import { Currency } from './styled';
import { getSymbolFromCurrency } from '../../../utils/helpers/getSymbolFromCurrency';
import ManatIcon from '../../../public/static/images/currency/manat-brown.svg';
import Icon from '../../Icon';

export const CurrencySymbol = ({ currency }: { currency: string }) => (
  <Currency $currency={currency}>
    {currency === 'AZN' ? (
      <Icon IconComponent={ManatIcon} width={11} height={11} />
    ) : (
      getSymbolFromCurrency(currency)
    )}
  </Currency>
);
