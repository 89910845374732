import styled from 'styled-components';

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-image: url('/static/images/bulk/selected-bulk.svg');
    background-position: center;
    border: 0;

    &::after {
      display: block;
    }
  }
`;
