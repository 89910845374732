import { TFunction } from 'react-i18next';
import { GetLocationName, ParamsList } from './types';
import { Cities } from '../../../utils/helpers/constants';

export const getLocationName = ({ city, location }: GetLocationName) => {
  if (!(city || location)) return;

  const isDefaultCity = city.id === Cities.Baku.id;

  if (location && isDefaultCity) return location.fullName;
  return city.name;
};

export const getParamsList = (
  { rooms, area, floor, floors }: ParamsList,
  t: TFunction
) => {
  return [
    rooms && ['rooms', t('cards.item_card.rooms', { count: rooms })],
    area && ['area', `${area.value} ${area.units}`],
    floor && ['floor', `${floor}/${floors} ${t('cards.item_card.floor')}`]
  ].filter(Boolean);
};
